import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';

import { CoreMenuItem } from '@core/types';
import { AnalitikaComponent } from 'app/main/pages/torzsek/pages/analitika/analitika.component';

@Component({
  selector: '[core-menu-vertical-item]',
  templateUrl: './item.component.html'
})
export class CoreMenuVerticalItemComponent {
  @Input()
  item: CoreMenuItem;

  constructor(private _coreSidebarService: CoreSidebarService, private router: Router) {

  }

  public toggleSidebar(): void {
    if(this._coreSidebarService.getSidebarRegistry('analitikaSidebar')) {
      this._coreSidebarService.getSidebarRegistry('analitikaSidebar').open();
    } else {
      this._coreSidebarService.setSidebarRegistry('analitikaSidebar', AnalitikaComponent);
      this._coreSidebarService.getSidebarRegistry('analitikaSidebar').open();
    }
  }
}
