<ng-container *ngTemplateOutlet="layout === 'horizontal' ? horizontal : vertical">
</ng-container>

<ng-template #vertical>
    <form formGroupName="cim" [ngStyle]="customStyle" cdkScrollable>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="custom-control custom-checkbox mb-1">
                        <input [id]="uniqueId" type="checkbox" class="custom-control-input" formControlName="kulfoldi" />
                        <label class="custom-control-label" [for]="uniqueId">Külföldi cím</label
            >
          </div>

          <div *ngIf="formGroup.get('cimtipus')" class="row">
            <div class="col">
              <div class="form-group">
                <label for="cimtipus">Címtipus</label>
                        <div class="input-group">
                            <ng-select id="cimtipus" class="w-100" [items]="cimtipusok$ | async" [clearable]="false" [virtualScroll]="true" bindLabel="nev" bindValue="nev" formControlName="cimtipus" [required]="'cimtipus' | requiredControl : formGroup">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="formGroup.get('kulfoldi')?.value;">
                <div class="col">
                    <div class="form-group">
                        <label for="orszag">Ország</label>
                        <div class="input-group">
                            <!-- <input id="orszag" class="form-control" formControlName="orszag" [required]="'orszag' | requiredControl : formGroup" /> -->
                            <ng-select id="orszag" class="w-100" [items]="orszagokData" [clearable]="false" [virtualScroll]="true" bindLabel="megnevezes" bindValue="megnevezes" formControlName="orszag" [required]="
                            'orszag' | requiredControl : formGroup
                          ">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-4">
                    <div class="form-group">
                        <label for="iranyitoszam">Irányítószám</label>
                        <div class="input-group">
                            <input id="iranyitoszam" class="form-control" [mask]="formGroup.get('kulfoldi')?.value ? null : '0000'" [type]="
                      formGroup.get('kulfoldi')?.value ? 'number' : 'text'
                    " formControlName="iranyitoszam" [required]="'iranyitoszam' | requiredControl : formGroup" />
                        </div>
                    </div>
                </div>

                <div class="col-8">
                    <div class="form-group">
                        <label for="telepules">Település</label>
                        <div class="input-group">
                            <!-- Magyar -->
                            <ng-select #telepulesSelect *ngIf="
                      !formGroup.get('kulfoldi')?.value;
                      else kulfoldiTelepulesTemplate
                    " id="telepules" class="w-100" [items]="telepulesekSrc" [clearable]="false" [virtualScroll]="true" bindLabel="nev" bindValue="nev" formControlName="telepules" [required]="'telepules' | requiredControl : formGroup" (change)="fillIrszOnTelepulesChange($event)">
                                <ng-template ng-option-tmp let-item="item">
                                    {{ item.nev }} <br />
                                    <small>{{ item.iranyitoszam }}</small>
                                </ng-template>
                            </ng-select>

                            <!-- Külföldi -->
                            <ng-template #kulfoldiTelepulesTemplate>
                                <input id="kulfoldi-telepules" class="form-control" formControlName="telepules" [required]="'telepules' | requiredControl : formGroup" />
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label for="kozteruletNeve">Közterület neve</label>
                        <div class="input-group">
                            <input id="kozteruletNeve" class="form-control" formControlName="kozterulet_neve" [required]="'kozterulet_neve' | requiredControl : formGroup" />
                        </div>
                    </div>
                </div>

                <div class="col-6">
                    <div class="form-group">
                        <label for="kozteruletTipusa">Közterület típusa</label>
                        <div class="input-group">
                            <!-- Magyar -->
                            <ng-select *ngIf="
                      !formGroup.get('kulfoldi')?.value;
                      else kulfoldiKozteruletTipusTemplate
                    " id="kozteruletTipusa" class="w-100" [items]="kozteruletTipusok" [clearable]="true" [virtualScroll]="true" bindLabel="tipus" bindValue="tipus" formControlName="kozterulet_tipusa" [required]="
                      'kozterulet_tipusa' | requiredControl : formGroup
                    ">
                                <ng-template ng-option-tmp let-item="item">
                                    <span [title]="item">{{ item }}</span>
                                </ng-template>
                            </ng-select>

                            <!-- Külföldi -->
                            <ng-template #kulfoldiKozteruletTipusTemplate>
                                <input id="kozterulet-tipusa" class="form-control" formControlName="kozterulet_tipusa" [required]="
                        'kozterulet_tipusa' | requiredControl : formGroup
                      " />
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="hazszam">Házszám</label>
                        <div class="input-group">
                            <input id="hazszam" class="form-control" formControlName="hazszam" [required]="'hazszam' | requiredControl : formGroup" />
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="form-group">
                        <label for="epulet">Épület</label>
                        <div class="input-group">
                            <input id="epulet" class="form-control" formControlName="epulet" [required]="'epulet' | requiredControl : formGroup" />
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="form-group">
                        <label for="emelet">Emelet</label>
                        <div class="input-group">
                            <input id="emelet" class="form-control" formControlName="emelet" [required]="'emelet' | requiredControl : formGroup" />
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="form-group">
                        <label for="ajto">Ajtó</label>
                        <div class="input-group">
                            <input id="ajto" class="form-control" formControlName="ajto" [required]="'ajto' | requiredControl : formGroup" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
    </form>
</ng-template>

<ng-template #horizontal>
    <form class="form form-horizontal" formGroupName="cim" [ngStyle]="customStyle" cdkScrollable>
        <div class="row py-1rem">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6">
                <div class="row checkbox-row">
                    <div class="col">
                        <div class="form-group row">
                            <div class="col-sm-3 col-xxl-4 col-form-label">
                                <label>Külföldi cím</label>
                            </div>

                            <div class="col-sm-9 col-xxl-8">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="kulfoldi" formControlName="kulfoldi" />
                                    <label class="custom-control-label" for="kulfoldi"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="formGroup.get('cimtipus')" class="row">
                    <div class="col">
                        <div class="form-group row">
                            <div class="col-sm-3 col-xxl-4 col-form-label">
                                <label for="cimtipus">Címtipus</label>
                            </div>

                            <div class="col-sm-9 col-xxl-8">
                                <ng-select id="cimtipus" class="w-100" [items]="cimtipusok$ | async" [clearable]="false" [virtualScroll]="true" bindLabel="nev" bindValue="nev" formControlName="cimtipus" [required]="'cimtipus' | requiredControl : formGroup">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row" *ngIf="formGroup.get('kulfoldi')?.value;">
                    <div class="col">
                        <div class="form-group row">
                            <div class="col-sm-3 col-xxl-4 col-form-label">
                                <label for="orszag">Ország</label>
                            </div>
                            <div class="col-sm-9 col-xxl-8">
                                <!-- <input id="orszag" class="form-control" formControlName="orszag" [required]="'orszag' | requiredControl : formGroup" /> -->
                                <ng-select id="orszag" class="w-100" [items]="orszagokData" [clearable]="false" [virtualScroll]="true" bindLabel="megnevezes" bindValue="megnevezes" formControlName="orszag" [required]="
                                'orszag' | requiredControl : formGroup
                              ">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group row">
                            <div class="col-sm-3 col-xxl-4 col-form-label">
                                <label for="iranyitoszam">Irányítószám</label>
                            </div>

                            <div class="col-3">
                                <input id="iranyitoszam" class="form-control" [mask]="formGroup.get('kulfoldi')?.value ? null : '0000'" formControlName="iranyitoszam" [type]="formGroup.get('kulfoldi')?.value ? 'number' : 'text'" [required]="'iranyitoszam' | requiredControl : formGroup"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group row">
                            <div class="col-sm-3 col-xxl-4 col-form-label">
                                <label for="telepules">Település</label>
                            </div>

                            <div class="col-sm-9 col-xxl-8">
                                <!-- Magyar -->
                                <ng-select #telepulesSelect *ngIf="
                    !formGroup.get('kulfoldi')?.value;
                    else kulfoldiTelepulesTemplate
                  " id="telepules" class="w-100" [items]="telepulesekSrc" [clearable]="false" [virtualScroll]="true" bindLabel="nev" bindValue="nev" formControlName="telepules" [required]="'telepules' | requiredControl : formGroup" (change)="fillIrszOnTelepulesChange($event)">
                                    <ng-template ng-option-tmp let-item="item">
                                        {{ item.nev }} <br />
                                        <small>{{ item.iranyitoszam }}</small>
                                    </ng-template>
                                </ng-select>

                                <!-- Külföldi -->
                                <ng-template #kulfoldiTelepulesTemplate>
                                    <input id="kulfoldi-telepules" class="form-control" formControlName="telepules" [required]="'telepules' | requiredControl : formGroup" />
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group row">
                            <div class="col-sm-3 col-xxl-4 col-form-label">
                                <label for="kozteruletNeve">Közterület neve</label>
                            </div>

                            <div class="col">
                                <input id="kozteruletNeve" class="form-control" formControlName="kozterulet_neve" [required]="'kozterulet_neve' | requiredControl : formGroup" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group row">
                            <div class="col-sm-3 col-xxl-4 col-form-label">
                                <label for="kozteruletTipusa">Közterület típusa</label>
                            </div>

                            <div class="col">
                                <!-- Magyar -->
                                <ng-select *ngIf="
                    !formGroup.get('kulfoldi')?.value;
                    else kulfoldiKozteruletTipusTemplate
                  " id="kozteruletTipusa" class="w-100" [items]="kozteruletTipusok" [clearable]="false" [virtualScroll]="true" bindLabel="tipus" bindValue="tipus" formControlName="kozterulet_tipusa" [required]="'kozterulet_tipusa' | requiredControl : formGroup">
                                    <ng-template ng-option-tmp let-item="item">
                                        <span [title]="item">{{ item }}</span>
                                    </ng-template>
                                </ng-select>

                                <!-- Külföldi -->
                                <ng-template #kulfoldiKozteruletTipusTemplate>
                                    <input id="kozterulet-tipusa" class="form-control" formControlName="kozterulet_tipusa" [required]="
                      'kozterulet_tipusa' | requiredControl : formGroup
                    " />
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group row">
                            <div class="col-sm-3 col-xxl-4 col-form-label">
                                <label for="hazszam">Házszám</label>
                            </div>

                            <div class="col-3">
                                <input id="hazszam" class="form-control" formControlName="hazszam" [required]="'hazszam' | requiredControl : formGroup" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group row">
                            <div class="col-sm-3 col-xxl-4 col-form-label">
                                <label for="epulet">Épület, emelet, ajtó</label>
                            </div>

                            <div class="col-sm-9 col-xxl-8">
                                <div class="d-flex flex-row" [ngStyle]="{ gap: '5px' }">
                                    <input id="epulet" class="form-control" formControlName="epulet" [required]="'epulet' | requiredControl : formGroup" />

                                    <input id="emelet" class="form-control" formControlName="emelet" [required]="'emelet' | requiredControl : formGroup" />

                                    <input id="ajto" class="form-control" formControlName="ajto" [required]="'ajto' | requiredControl : formGroup" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>