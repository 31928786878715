import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';

@Component({
  selector: 'app-torzsek-details',
  templateUrl: './torzsek-details.component.html',
  styleUrls: ['./torzsek-details.component.scss'],
})
export class TorzsekDetailsComponent implements OnInit, OnDestroy {
  @Input() modalTitle: string;
  @Input() editMode: boolean;
  @Input() invalidForm: boolean;
  @Input() disableActions?: boolean;
  @Input() footerTemplate?: TemplateRef<any>;
  @Input() saveBtnTitle: string = 'Mentés';
  @Input() updateBtnTitle: string = 'Módosítás';
  @Input() bodyClass: string = '';
  @Input() openSidebar: boolean = true;
  @Output() submit = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  public torzs: string;

  constructor(
    private _coreSidebarService: CoreSidebarService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.torzs = this._router.url.split('/')[2];

    if (this.openSidebar) {
      setTimeout(() => {
        this._coreSidebarService.getSidebarRegistry('themeCustomizer').close();
        this._coreSidebarService.getSidebarRegistry('details-sidebar')?.open();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.openSidebar) {
      this._coreSidebarService.getSidebarRegistry('details-sidebar').close();
    }
  }

  closeSidebar(): void {
    this.cancel.emit();
  }

  onSubmit(): void {
    this.submit.emit();
  }
}
