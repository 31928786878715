<div class="overlay" *ngIf="loading">
    <div class="spinner-border mr-50 text-primary" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<!-- vertical-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'vertical'">
    <vertical-layout></vertical-layout>
</ng-container>
<!-- / vertical-layout -->

<!-- horizontal-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'horizontal'">
    <horizontal-layout></horizontal-layout>
</ng-container>
<!-- / horizontal-layout -->

<!-- theme customizer -->
<core-sidebar name="themeCustomizer" class="customizer d-none d-md-block" [invisibleOverlay]="true" *ngIf="coreConfig.layout.customizer">
    <core-theme-customizer>
        <core-user-settings></core-user-settings>
    </core-theme-customizer>
</core-sidebar>
<!-- / theme customizer -->

<!-- theme customizer -->
<core-sidebar name="analitikaSidebar" class="customizer d-none d-md-block" [overlayVisibility]="true">
    <app-analitika></app-analitika>
</core-sidebar>
<!-- / theme customizer -->

<ng-keyboard-shortcuts-help [key]="'f1'" [closeKey]="'escape'" [title]="'Súgó'"></ng-keyboard-shortcuts-help>

<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>